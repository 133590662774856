import styled from '@emotion/styled'
import { AboutMe, AboutMeContinued } from '../../../data/About'

const Block1 = styled.div`
    margin-bottom: 1rem;
`
const Block2 = styled.div``
export const About = ()=> {
    return(
        <div>
        <Block1>
           {AboutMe}
        </Block1>
        <Block2>
            {AboutMeContinued}
        </Block2>
        </div>
    )
}