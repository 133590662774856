import React from 'react';

export type Skill= {
    id: string;
    name: string;
    rating: string;
    details: string;
}

export const Skills: Skill[] = [
    {id: '',name: 'Agile', rating: '★★★★★', details: `I have worked in teams using agile for the last 4 years. We have used both Scrum and Kanban. For some of this time I have held the Scrum master role.`},
    {id: 'SOLID',name: 'Solid Principles', rating: '★★★', details: 'The solid principles have been a powerful tool for managing and developing both legacy and new applications. This has helped reduce firefighting, improved stability, reliability and improved maintainability.'},
    {id: 'DevOps',name: 'DevOps', rating: '★★★★', details: 'I have been using DevOps principles over the last few years to help align development tools, infrastructure and CI/CD pipelines. This has greatly improved output performance and simplified the development process.'},
    {id: 'TDD',name: 'Test Driven Development', rating: '★★★★', details: `TDD has greatly improved the testability of my code. I have found it results in simpler and better tested code and helps focus attention on how your code will be used and tested.`},
    {id: 'BDD',name: 'Behavior Driven Development ', rating: '★★★★', details: 'I have used BDD to help scope the work being done and found it a powerful tool for teasing information from stakeholders.'},
    {id: 'DDD',name: 'Domain Driven Design', rating: '★★', details: 'This has been a new area for me. But so far, a very powerful one. This process has helped focus development on what the application is going to be doing rather than what we think it should do and keep solutions and business processes aligned.'}
];
