import { CommandHandler } from '.';
import { GridLine, GridLineColumn1, GridLineColumn2, Line } from '..';
import { Skill, Skills } from '../../../data/Skills';

function getSkillText(skill: Skill): string{
    if(skill.id)
    {
        return `${skill.name}(${skill.id})`;
    }

    return  `${skill.name}`;
}

const skillNames = Skills.map(skill => (
    getSkillText(skill)
    )).join('\n')
const skillRatings = Skills.map(skill => (
    `${skill.rating}`
    )).join('\n')


function RenderSkillsTable(){
    return(
        <GridLine>
            <GridLineColumn1>
                {skillNames}
            </GridLineColumn1>
            <GridLineColumn2>
                {skillRatings}
            </GridLineColumn2>
        </GridLine>
    )
}

function RenderSkillDetail(skill: Skill){
    var responseText = `Name: ${skill?.name}
                Competency: ${skill?.rating}
                Details: ${skill?.details}`;

    return(
        <Line>
            {responseText} 
        </Line>
    )
}

function RenderError(){
    return(
        <Line>
            {`invalid command`}
        </Line>
    )
}
export const SkillsHandler: CommandHandler = {
    process: function (command: string, args: string[]) {
        var error = RenderError();
        var response = {line: {content: error}, overwriteLast: false}

        if(command)
        {
            if(command == 'get')
            {
                var contextName = args.join(' ');
                var skill = Skills.find(t=>  t.name.toLowerCase() == contextName);
                if(skill)
                {
                    var responseDetail = RenderSkillDetail(skill);
                    response = {line: {content: responseDetail}, overwriteLast: false}
                }                
            }
            else if(command == 'list'){
                var skillTable = RenderSkillsTable();
                response = {line: {content: skillTable}, overwriteLast: false};
            }
        }
        return [
            response
        ]
    }
}