import styled from '@emotion/styled'
import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import { FaChevronDown } from "react-icons/fa";

type CardLayout<T = {}> = 
T & { children?: React.ReactNode, title: React.ReactNode,  rating: React.ReactNode, content: React.ReactNode, };

type CardViewState= {
    height: string;
    expanded: boolean;
}

type ToggleProps= {
    expanded: boolean;
}

const CardContainer= styled.div`
    background: inherit;
    cursor: default;
    overflow: hidden;
`

const Content = styled.div`
    overflow: hidden;
    white-space: pre-wrap;
    margin-bottom: 1rem;
`;


const TitleLine = styled.div<ToggleProps>`
    display: grid;
    grid-template-columns: 8rem auto 2rem;
    transition: margin-top 500ms, margin-bottom 500ms;
    margin-top: 0rem;
    margin-bottom: 0rem;
    ${props => {
        if(props.expanded)
        {
            return `
            margin-top: 1rem;
            margin-bottom: 1rem;
            `
        }
      }}
`

const Title = styled.div`
    grid-column: 1 / 2;
`

const Rating = styled.div`
    grid-column: 2 / 3;
    color: #FFD13A;
`

const Icon = styled.div<ToggleProps>`
    grid-column: 3 / 4;
    transform-origin: center;
    transform: rotate(0deg);
    transition: transform 500ms;
    width: 1rem;
    height: 1rem;
    ${props => {
        if(props.expanded)
        {
            return `
            transform-origin: center;
            transform: rotate(180deg);
            `
        }
      }}
  
`

export default class Example extends Component<CardLayout, CardViewState> {
    constructor(props: CardLayout) {
        super(props)
        this.state = {
            height: "0",
            expanded: false
        };
        this.toggle = this.toggle.bind(this);
    }
   
    toggle = () => {
      const { height } = this.state;
      this.setState({
        height: height === "0" ? 'auto' : "0",
        expanded: !this.state.expanded
      });
    };
   
    render() {
      const { height, expanded } = this.state;
   
      return (
        <div>
            <CardContainer onClick={this.toggle}>
                <TitleLine expanded={expanded}>
                    <Title>{this.props.title}</Title>
                    <Rating>{this.props.rating}</Rating>
                    <Icon expanded={expanded}><FaChevronDown></FaChevronDown></Icon>
                </TitleLine>
            <AnimateHeight duration={ 500 } height={ height }>
                 <Content>{this.props.content}</Content>
            </AnimateHeight>
          </CardContainer>
        </div>
      );
    }
  }