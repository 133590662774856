import { CommandHandler } from '.';
import { GridLine, GridLineColumn1, GridLineColumn2, Line } from '..';
import { Language, Languages } from '../../../data/Languages';

const languageNames = Languages.map(language => (
    `${language.name}`
    )).join('\n')
const languageRatings = Languages.map(language => (
    `${language.rating}`
    )).join('\n')


function RenderLanguagesTable(){
    return(
        <GridLine>
            <GridLineColumn1>
                {languageNames}
            </GridLineColumn1>
            <GridLineColumn2>
                {languageRatings}
            </GridLineColumn2>
        </GridLine>
    )
}

function RenderLanguagesDetail(language: Language){
    var responseText = `Name: ${language?.name}
                Competency: ${language?.rating}
                Details: ${language?.details}`;

    return(
        <Line>
            {responseText} 
        </Line>
    )
}

function RenderError(){
    return(
        <Line>
            {`invalid command`}
        </Line>
    )
}
export const LanguagesHandler: CommandHandler = {
    process: function (command: string, args: string[]) {
        var error = RenderError();
        var response = {line: {content: error}, overwriteLast: false}

        if(command)
        {
            if(command == 'get')
            {
                var contextName = args.join(' ');
                var language = Languages.find(t=>  t.name.toLowerCase() == contextName);
                if(language)
                {
                    var responseDetail = RenderLanguagesDetail(language);
                    response = {line: {content: responseDetail}, overwriteLast: false}
                }                
            }
            else if(command == 'list'){
                var languageTable = RenderLanguagesTable();
                response = {line: {content: languageTable}, overwriteLast: false};
            }
        }
        return [
            response
        ]
    }
}