import React from 'react';
import { CommandHandler } from '.';
import { GridLine, GridLineColumn1, GridLineColumn2 } from '..';
import { Links } from '../../../data/Links';

function GetLinks(){
    return(
        <GridLine>
            <GridLineColumn1>
                {Links.map(link => (
                    <span>{link.name}<br/></span>
                ))}
            </GridLineColumn1>
            <GridLineColumn2>
                {Links.map(link => (
                    <a href={link.uri} target="_blank">{link.uri}<br/></a>
                ))}
            </GridLineColumn2>
        </GridLine>
    )
}

export const LinksHandler: CommandHandler = {
    process: function (context: string, commands: string[]) {
        var links = GetLinks()
        return [
            {line: {content: links, column1: ``}, overwriteLast: false},
        ]
    }
}