import React from 'react';

export type Language= {
    name: string;
    rating: string;
    details: string;
}

export const Languages: Language[] = [
    {name: 'C#', rating: '★★★★★', details: `I have been programing in C# for around 15 years. This is my go to language for services, applications and workers.`},
    {name: 'Powershell', rating: '★★★★', details: `I have used powershell for over 10 years. It is a tool I typically reserve for scripting and CI/CD based activity.`},
    {name: 'Typescript', rating: '★★★', details: `Though I have only been using Typescript for a short time now along with React it has become my go to language for browser UI development.`},
    {name: 'Javascript', rating: '★★', details: `Though I typicaly use Typescript I have from time to time worked in Javascript. Typically existing applications.`},
    {name: 'Java', rating: '★★', details: `Over the years I have worked with Java from time to time. Though infrequent I have always found it relativly straight forward to contribute to a Java project.`},
    {name: 'C', rating: '★★', details: `Most of my experience in C has been via using micro controllers like Arduino. With this I have created various projects from basic IOT style devices to measures/monitors or peripherals.`}
];
