import styled from '@emotion/styled'

export const GridLine = styled.div`
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);;
`
export const GridLineHeader = styled.div`
    white-space: pre-line;
    grid-column: 1 / 3;
    margin-bottom: 1rem;
`

export const GridLineColumn1 = styled.div`
    white-space: pre-line;
    grid-column: 1 / 2;
`
export const GridLineColumn2 = styled.div`
    white-space: pre-line;
    grid-column: 2 / 3;
    margin-left: 1rem;
`