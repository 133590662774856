import styled from '@emotion/styled';
import React from 'react';
import { Languages } from '../../../data/Languages';
import Card from '../Card';

const Line = styled.div`
    margin-bottom: 0.2rem;
`

export const LanguageTable = ()=> {
    return(
        <div>
            {Languages.map(language => (
                <Line>
                    <Card key={language.name} title={language.name} rating={language.rating} content={language.details}  >
                    </Card>
                </Line>
            ))}
        </div>
    )
}