import React, { createRef, useState} from 'react';
import './App.css';
import styled from '@emotion/styled'
import ConsoleView from './components/ConsoleView/ConsoleView';
import { CommandHandlers } from './components/ConsoleView/handlers/Handlers';
import { AboutHandler, HelpHandler, SkillsHandler, TechHandler, LinksHandler, LanguagesHandler, InterestsHandler } from './components/ConsoleView/handlers';
import { Scrollbars } from 'react-custom-scrollbars';
import { BlurredBox, ToggleControl, About, TechTable, SkillTable, Panel } from './components';
import { CareerTable } from './components/UiView/content/CareerTable';
import { InterestsTable } from './components/UiView/content/InterestsTable';
import { LanguageTable } from './components/UiView/content/LanguageTable';
import { CareersHandler } from './components/ConsoleView/handlers/CareerHandler';

const PageGrid = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  grid-gap: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  @media (min-width: 800px) { 
    flex-direction: row;
  }
  `

const MainGrid = styled.div<ToggleProps>`
  display: flex;
  grid-gap: 0px;
  padding: 0px;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  justify-self: stretch;
  align-self: stretch;
  ${props => {
    if (props.toggle) {
      return `
        
      `;
    } else {
      return `

      `;
    }
  }}
  `

type ToggleProps = {
  toggle: boolean
}

const ModuleDiv = styled.div<ToggleProps>`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  justify-self: center;
  align-self: center;

  ${props => {
    if (props.toggle) {
      return `
        height: 100%;
        overflow: visible;
      `;
    } else {
      return `
        max-height: 500px;
        height: 100%;
      `;
    }
  }}
`;

const DisplayDiv = styled.div<ToggleProps>`
  height: 100%;
  justify-self: center;
  align-self: center;
`;

const FlexGrid = styled.div`
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
`;

const FlexCell= styled.div`
  flex: auto;
`;

const WelcomeLines = [
  {line: {content: `-- About Dan --`}, overwriteLast: true},
  {line: {content: `To get started type a command followed by the context e.g. list skills`}, overwriteLast: false},
  {line: {content: `For more information enter 'help'`}, overwriteLast: false}
]

const handlers: CommandHandlers = {
  help: HelpHandler,
  skills: SkillsHandler,
  skill: SkillsHandler,
  tech: TechHandler,
  about: AboutHandler,
  links: LinksHandler,
  languages: LanguagesHandler,
  language: LanguagesHandler,
  interests: InterestsHandler,
  interest: InterestsHandler,
  career: CareersHandler
}

function App() {
  const consoleView = createRef<ConsoleView>();
  const [isChecked, setIsChecked] = useState(true);

  function UpdateSize(e: React.ChangeEvent<HTMLInputElement>)
  {
    setIsChecked(e.currentTarget.checked)
  }

  return (
    <PageGrid>
        <ToggleControl checkedtext='UI' uncheckedtext='CLI' onChange={UpdateSize}>
        </ToggleControl>
        <MainGrid toggle={isChecked}>
          <ModuleDiv toggle={isChecked}>
              {!isChecked &&(
              <BlurredBox>
                <DisplayDiv toggle={isChecked}>
                  <ConsoleView ref={consoleView} handlers={handlers} welcomeLines={WelcomeLines}></ConsoleView>
                </DisplayDiv>
              </BlurredBox>
              )}
              {isChecked &&(
                <Scrollbars autoHide={true}>
                    <FlexGrid>
                      <FlexCell>
                        <Panel width='large' delay={0} title='About Me'><About></About></Panel>
                      </FlexCell>
                      <FlexCell>
                        <Panel width='medium' delay={100} title='Career Highlights'><CareerTable></CareerTable></Panel>
                        <Panel width='medium' delay={200} title='Interests'><InterestsTable></InterestsTable></Panel>
                      </FlexCell>
                      <FlexCell>
                        <Panel width='small' delay={100} title='Soft Skills'><SkillTable></SkillTable></Panel>
                        <Panel width='small' delay={200} title='Technologies'><TechTable></TechTable></Panel>
                        <Panel width='small' delay={300} title='Languages'><LanguageTable></LanguageTable></Panel>
                      </FlexCell>
                    </FlexGrid>
                </Scrollbars>
              )}
          </ModuleDiv>
        </MainGrid>
    </PageGrid>
  );
}

export default App;
