export enum ActionType {
  SetLines = "SetLines"
}

export type Action =
  | { type: ActionType.SetLines; payload: string[] }

interface SetLines {
  type: ActionType.SetLines;
  payload: any;
}

const SetLines = (value: any): SetLines => ({
  type: ActionType.SetLines,
  payload: value,
});

export const Actions = {
  SetLines
};
