import React from 'react';

export type CareerHighlight= {
    name: string;
    details: string;
}

export const CareerHighlights: CareerHighlight[] = [
    {name: 'DevOps', details: `At an orgonisation I worked for our core CI process was one single monolithic process built on Teamcity. This process took around 14 hours to complete and failures typically required a full re-run. To resolve this, I instigated and worked to move from a single monolithic pipeline to a spread of discrete CI and CD pipelines in Azure DevOps, in the process refactoring/simplifying our technologies and builds. This significantly improved the speed of development taking our build processes from around 14 hours to under 15 mins. We also streamlined the development and deployment process so each area of the solution could then be built and deployed individually. With all of this we were also able to reduce our release time from 1-2 months to multiple releases per week.`},
    {name: 'Kubernetes', details: `A particuarly old solution I worked within the infrastructure was built using typical on-premise patterns and designs. To start addressing this I introduced Kubernetes. This allowed us to run a cluster alongside a legacy environment, giving us a platform to start breaking down components of the solution and making them cloud native.`},
    {name: 'API Testing', details: `Another product I developed for was a .NET API. The testing of this was very in-depth and time consuming, up to 1 week for a full test. We needed to reduce this time but didn’t want to compromise any coverage and depth of testing so needed to look for a way to parallelise the process. To solve this, I used Azure VM Scale Sets and DevOps scalable agent pools. We then broke the testing process down into smaller chunks and implemented them as individual jobs. This allowed us to run an almost unlimited number of agents to run each of the test jobs. This brought the testing process down from 1 week to 15 hours.`},
    {name: 'Document Output System', details: `One of my largest projects was the development and implementation of a new document generation system. This had complex requirements for dynamic formatting and output of documents to various systems or formats. The solution utilised a product called "Formate" for the actual document rendering for which I developed the necessary supporting programs written in Formate’s proprietary language. For user interaction I developed a bespoke web front end written in ASP.Net to manage the generated documents, emails and user’s preferences. I also identified issues with the encoding of files sent to the solution from the companies iSeries where files were using different encoding types. With no easy way to determine the type by the type of document sent I updated the inbound processing to determine the encoding and convert the file to UTF for delivery to the document generation servers. In addition to this I developed a desktop notification client so users could see the status of their print jobs. The client utilized SignalR for push communications allowing it to get up to the second updates. As the Formate product didn’t have the ability to send any form of communications I chose to add a .net function to all the necessary stored procedures. This .net function created a text file containing the necessary details. A file watcher service then picked up and processed this file sending the necessary SignalR messages. This kept the load on the SQL server to an absolute minimum and only marginally increased the execution times for the stored procedures.`}
];
