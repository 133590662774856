import styled from '@emotion/styled'
import React from 'react';

type WithChildren<T = {}> = 
T & { children?: React.ReactNode, delay: number, title: string, width: string };

type AnimationDivParams = {
  delay: number
  width: string
}

export function Panel({ children, delay, title, width}: WithChildren){

  const PanelStyle = styled.div<AnimationDivParams>`
    margin: 1rem;
    background: inherit;
    backdrop-filter: blur(20px);
    opacity: 0;
    color: #f8f8f2;
    font-family: "Jura";
    ${props => {
      switch(props.width) {
        case 'small':
          return `width: 350px;`
        case 'medium':
          return `
            min-width: 400px;
            max-width: 750px;`
        case 'large':
            return `
            min-width: 400px;
            max-width: 1150px;`
        default:
          return ``
      }
    }}
 

    animation-fill-mode: forwards;
    animation-name: start-animation;
    animation-duration: 500ms;

    ${props => {
      return `animation-delay: ${props.delay}ms;`
    }}

    @keyframes start-animation
    {
        0% {
            opacity: 0;
            transform: scale3d(0.3, 0.3, 0.3);
        }
        
        50% {
            opacity: 1;
        }

        100% {
            opacity: 1;
        }
    }

  `

  const ContentPanel = styled.div`
    background-image: linear-gradient(to right, rgba(200,200,200,0.3), rgba(200,200,200,0.05));
    padding: 1rem;
  `

  const Content = styled(ContentPanel)`
    border-radius: 0px 0px 4px 4px;
    margin: 0.2rem 0rem 0rem 0rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  `

  const Title = styled(ContentPanel)`
    border-radius: 4px 4px 0px 0px;
    margin: 0.2rem 0rem 0rem 0rem;
    box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 20px 0 rgba(0, 0, 0, 0.19);
    font-size: 20px;
  `

  return (
        <PanelStyle width={width} delay={delay}>
          <Title>{title}</Title>
          <Content>
            {children}
          </Content>
        </PanelStyle>
  );
}
