import styled from '@emotion/styled';
import { CommandHandler } from '.';
import { AboutMe, AboutMeContinued } from '../../../data/About';

const Container = styled.div`
    `

function RenderAbout(){
    return(
    <Container>
        <Container>
            {AboutMe}
        </Container>
        <Container>
            {AboutMeContinued}
        </Container>
    </Container>
    )
}

export const AboutHandler: CommandHandler = {
    process: function (context: string, commands: string[]) {
        var about = RenderAbout()
        return [
            {line: {content: about}, overwriteLast: false},
        ]
    }
}