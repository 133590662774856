import React, { useContext } from "react";
import { Action } from "./actions";

export interface StateContext {
  lines: string[];
}

export interface Store {
  globalState: StateContext;
  dispatch: React.Dispatch<Action>;
}

export const defaultState: StateContext = {
  lines: new Array<string>()
};

export const AppContext = React.createContext<Store>({
  globalState: defaultState,
  dispatch: () => null,
});

export const useLinesStateContext = () => useContext(AppContext);
