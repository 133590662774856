import React from 'react';

export type Technology= {
    name: string;
    rating: string;
    details: string;
}

export const Technologies: Technology[] = [
    {name: '.Net Core', rating: '★★★★★', details: `I have been using .Net Core for several years now. Primarily in container environments running on Kubernetes.

I have experience in using the following .Net Core technologies:
        ○ Web Api
        ○ WPF
        ○ Service Workers
        ○ Dependency Injection
    `},
    {name: '.Net Framework', rating: '★★★★★', details: 'I have been using .Net Framework for around 15 years. Up until .Net Core this had been my go to framework and runtime.'},
    {name: 'Kubernetes', rating: '★★★★', details: `I have been using Kubernetes for several years now. This has been mainly to run internal production services but recently we have started branching out into hosting some external services. I have successfully introduced this to my current organisation to help us move to a more manageable development life cycle and help us break down our legacy monoliths.`},
    {name: 'Docker', rating: '★★★★', details: 'I have been using Docker for several years now. This has been to build containers for our Kubernetes deployments. I have successfully introduced this to my current organisation alongside Kubernetes.'},
    {name: `Rest API's`, rating: '★★★★', details: `I have many years experience creating Restfull API's. They are my typical go to API choice.`},
    {name: 'SQL Server', rating: '★★★★', details: 'SQL Server has been the mainstay of databases for me. I have around 11 years’ experience and seen many different products and scenarios in which SQL Server has been used.'},
    {name: 'Azure', rating: '★★★★', details: `Azure was my first cloud platform and is where the core of my cloud knowledge sits. I have work on automating full envrionment deployments going from zero to fully deployed running just one command.

    These are some of the Azure technologies I have experience using:
        ○ Azure Kubernetes
        ○ App Services
        ○ Cosmos DB
        ○ API Management 
        ○ App Gateway
        ○ Virtual Machines
        ○ Azure SQL
    `},
    {name: 'Azure DevOps', rating: '★★★★★', details: 'During my current employment I managed the migration of our builds and deployments to Azure DevOps. This allowed us to greatly improve the manageability of our build and deployments. Allowing us to store their build definitions in source control and automate almost all the development and deployment life cycle.'},
    {name: 'Mongo DB', rating: '★★★★', details: 'I have used Mongo DB in several environments, and I have found it a powerfull tool where document databases are appropriate.'},
    {name: 'Networking', rating: '★★★', details: 'I have many years networking experience ranging from basic TCP IP Lans, Wans to Wireless technologies and IP telephony.'},
];
