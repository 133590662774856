import styled from '@emotion/styled';
import React from 'react';
import { Skills } from '../../../data/Skills';
import Card from '../Card';

const Line = styled.div`
    margin-bottom: 0.2rem;
`
export const SkillTable = ()=> {
    return(
        <div>
            {Skills.map(skill => (
                <Line>
                    <Card key={skill.name} title={skill.name} rating={skill.rating} content={skill.details}  >
                    </Card>
                </Line>
            ))}
        </div>
    )
}