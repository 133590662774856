import { Action, ActionType } from "./actions";
import { StateContext } from "./store";

export const reducer = (state: StateContext, action: Action) => {
  switch (action.type) {
    case ActionType.SetLines:
      return { ...state, lines: action.payload };
      default:
        throw new Error("Not among actions");
  }
};
