import { CommandHandler } from '.';
import { GridLine, GridLineColumn1, GridLineColumn2, Line } from '..';
import { Technologies, Technology } from '../../../data/Tech';

const techNames = Technologies.map(technology => (
    `${technology.name}`
    )).join('\n')
const techRatings = Technologies.map(technology => (
    `${technology.rating}`
    )).join('\n')

export function RenderTechTable(){
    return(
        <GridLine>
            <GridLineColumn1>
                {techNames}
            </GridLineColumn1>
            <GridLineColumn2>
                {techRatings}
            </GridLineColumn2>
        </GridLine>
    )
}

function RenderTechDetail(technology: Technology){
    var responseText = `Name: ${technology?.name}
                Competency: ${technology?.rating}
                Details: ${technology?.details}`;

    return(
        <Line>
            {responseText} 
        </Line>
    )
}

function RenderError(){
    return(
        <Line>
            {`invalid command`}
        </Line>
    )
}

export const TechHandler: CommandHandler = {
    process: function (command: string, args: string[]) {
        var error = RenderError();
        var response = {line: {content: error}, overwriteLast: false}

        if(command)
        {
            if(command == 'get')
            {
                var contextName = args.join(' ');
                var technology = Technologies.find(t=>  t.name.toLowerCase() == contextName);
                if(technology)
                {
                    var responseDetail = RenderTechDetail(technology);
                    response = {line: {content: responseDetail}, overwriteLast: false}
                }                
            }
            else if(command == 'list'){
                var techTable = RenderTechTable();
                response = {line: {content: techTable}, overwriteLast: false};
            }
        }
        return [
            response
        ]
    }
}