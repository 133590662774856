import styled from '@emotion/styled'
import { CareerHighlights } from '../../../data/CareerHighlights'

const Line = styled.div`
    margin-bottom: 1rem;
`
const Title = styled.div`

`
const Details = styled.div`

`
export const CareerTable = ()=> {
    return(
        <div>
            {CareerHighlights.map(highlight => (
                <Line>
                    <Title>{highlight.name}</Title>
                    <Details>{highlight.details}</Details>
                </Line>
            ))}
        </div>
    )
}