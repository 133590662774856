import styled from '@emotion/styled'
import { Component, createRef } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { CommandHandlers } from './handlers/Handlers';
import { Line } from '.';

const Input = styled.div`
    font-family: "Lucida Console", "Lucida Sans Typewriter", monaco, "Bitstream Vera Sans Mono";
    font-size: 12px;
    line-height: 1.4;
    color: #FFFFFF;
    outline: none;
    background: inherit;
    border: none;
    width: 100%;
    resize: none;
    overflow: hidden;
    height: auto;
    `
       
const EditableLine = styled.div`
    outline: none;
    background: inherit;
    border: none;
    `
const CommandLine = styled.div`
    color: #f8f8f2;
    display: grid;
    grid-template-columns: auto minmax(0, 1fr);
    `
const LineSuffix = styled.div`
    padding-right: 0.5rem;
    `
const SuffixStart = styled.span`
    color: #7CD1E2;
    `
const SuffixEnd = styled.span`
    color: #ffb86c;
    `

const Grid = styled.div`
    display: grid;
    grid-template-columns: min-content auto;
`
const StartUpLines: CommandLine[] = [];
const StartUpNewLines: LineUpdate[]  = []

export type LineUpdate= {
    line: CommandLine;
    overwriteLast: boolean;
}

export type CommandLine= {
    content: JSX.Element | string;
}

export type ConsoleViewProps= {
    handlers: CommandHandlers;
    welcomeLines: LineUpdate[];
}

export type ConsoleViewState= {
    lines: CommandLine[];
    newLines: LineUpdate[];
    focused: boolean;
    userId: string;
    intervalId: any;
    allowInput: boolean;
    loaded: boolean;
}

export default class ConsoleView extends Component<ConsoleViewProps, ConsoleViewState> {
    constructor(props: ConsoleViewProps) {
        super(props)
        this.state = {
            lines: StartUpLines,
            newLines: StartUpNewLines,
            focused: false,
            userId: this.createUserId(5),
            intervalId: 0,
            allowInput: true,
            loaded: false
        };

        this.addLines = this.addLines.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.updatefocus = this.updatefocus.bind(this);
        this.ProcessInputInput = this.ProcessInputInput.bind(this);
        this.ProcessKeyInput = this.ProcessKeyInput.bind(this);
    }
    textInput = createRef<HTMLDivElement>();
    scrollbar = createRef<Scrollbars>();

    setupTimer(wait: number){
        var interval = setInterval(() => {
            if(this.state.newLines && this.state.newLines.length > 0)
            {
                this.setState({allowInput: false})
                if (this.state.newLines.length > 0) {
                    var newLine = this.state.newLines[0]
                    var currentLines = this.state.lines
    
                    if(newLine.overwriteLast)
                    {
                        currentLines.splice(-1,1);
                    }
    
                    this.setState({lines: [...currentLines, newLine.line]});
                    this.state.newLines.splice(0, 1);
                    this.setState({newLines: this.state.newLines})
                }
            }
            else{
                clearInterval(this.state.intervalId);
                this.setState({allowInput: true})
                if(!this.state.loaded)
                {
                    this.setState({loaded: true})
                    this.addLines(this.props.welcomeLines);
                }
                
            }
            this.scrollbar.current?.scrollToBottom();
            
        }, wait);
        this.setState({intervalId: interval})
    }

    componentDidMount() {
        this.setupTimer = this.setupTimer.bind(this);
        this.setupTimer(500);
    }

    addLines(e: LineUpdate[])
    {
        this.setupTimer = this.setupTimer.bind(this);
        this.setupTimer(50);
        this.setState({allowInput: false})
        this.setState({newLines: this.state.newLines.concat(e)})
    }

    ProcessKeyInput(e: React.KeyboardEvent<HTMLDivElement>)
    {
        if(e.key === 'Enter'){
            if(this.state.allowInput)
            {
                var value = e.currentTarget.innerText;
                this.setState({lines: [...this.state.lines, {content: value}]})
                e.currentTarget.innerText = "";
                var props = this.props as ConsoleViewProps
                this.newCommand(value);
            }
        }        
    }

    newCommand(e: string) {
        if(e != '')
        {
          var lines: LineUpdate[] = [];
          var segments =  e.split(' ');
    
          var command = segments[0];
          var context = segments[1];
          var commands = segments.slice(2);
    
          if(command)
          {
            command = command.toLowerCase()
            if(context)
            {
              context = context.toLowerCase()
              var handler = this.props.handlers[context];
            }
            else{
              var handler = this.props.handlers[command];
            }
           
            if(handler)
            {
              var response = handler.process(command, commands);
              lines = lines.concat(response);
            }
            else{
              lines.push({line: {content: 'command not found'}, overwriteLast: false});
            }
          }
          else{
            lines.push({line: {content:  'command not found'}, overwriteLast: false});
          }
          
          this.addLines(lines);
        }
    }
    
    ProcessInputInput(e: React.ChangeEvent<HTMLDivElement>)
    {
        var value = e.currentTarget.innerText;

        if(value.includes('\n'))
        {
            value = value.split('\n').join('');
            e.currentTarget.innerText = value;
        }
    }

    onBlur() {
        this.setState({focused: false})
    }
    onFocus() {
        this.setState({focused: true})
    }

    updatefocus() {
        if(!this.state.focused)
        {
            if(this.textInput)
            this.textInput.current?.focus();
            var char = this.textInput.current?.innerText.length;
            var sel = window.getSelection();
            if(this.textInput.current?.firstChild)
            {
                sel?.collapse(this.textInput.current?.firstChild, char);
            }
        }
    }

    createUserId(length: number) {
        var result = '';
        var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    RenderLine(line: CommandLine){
        let TypeofLine = typeof line.content;
        if(TypeofLine === "string")
        {
            return(
                <CommandLine>
                    <LineSuffix><SuffixStart>dan@about-me:</SuffixStart><SuffixEnd>user-{this.state.userId}$</SuffixEnd></LineSuffix>
                    <Line>{line.content}</Line>
                </CommandLine>
                )
        }
      else{
        return(
            <Line>{line.content}</Line>
        )
      }
    }

    render() {
        return (
            <Scrollbars autoHide={true} ref={this.scrollbar} onClick={this.updatefocus}>
                <Input spellCheck="false" onClick={this.updatefocus}>
                    {this.state.lines.map(line => this.RenderLine(line))}
                    <CommandLine>
                      <LineSuffix><SuffixStart>dan@about-me:</SuffixStart><SuffixEnd>user-{this.state.userId}$</SuffixEnd></LineSuffix>
                      <EditableLine ref={this.textInput} contentEditable={true} onFocus={this.onFocus} onBlur={this.onBlur} onInput={this.ProcessInputInput} onKeyPress={this.ProcessKeyInput}></EditableLine>
                    </CommandLine>
                </Input>
            </Scrollbars>
        );
    }
}

// export const ConsoleView = () => {
//     const [lines, setLines] = useState(StartUpLines);
//     const [focused, setFocus] = useState(false);
//     const textInput = createRef<HTMLDivElement>();

//     function ProcessKeyInput(e: React.KeyboardEvent<HTMLDivElement>)
//     {
//         if(e.key === 'Enter'){
//             var value = e.currentTarget.innerText;

//             setLines(lines => [...lines, value]);
//             e.currentTarget.innerText = "";
//         }        
//     }
    
//     function ProcessInputInput(e: React.ChangeEvent<HTMLDivElement>)
//     {
//         var value = e.currentTarget.innerText;

//         if(value.includes('\n'))
//         {
//             value = value.split('\n').join('');
//             e.currentTarget.innerText = value;
//         }
//     }

//     function onBlur() {
//         setFocus(false)
//     }
//     function onFocus() {
//         setFocus(true)
//     }

//     function focus() {
//         if(!focused)
//         {
//             if(textInput)
//             textInput.current?.focus();
//             var char = textInput.current?.innerText.length;
//             var sel = window.getSelection();
//             if(textInput.current?.firstChild)
//             {
//                 sel?.collapse(textInput.current?.firstChild, char);
//             }
//         }
//     }

//     return (
//         <Scrollbars style={{ height: 430 }} onClick={focus}>
//             <Input spellCheck="false" onClick={focus}>
//                 {lines.map(line => (
//                 <CommandLine>
//                   <LineSuffix><SuffixStart>dan@about-me:</SuffixStart><SuffixEnd>user$</SuffixEnd></LineSuffix>
//                   <Line>{line}</Line>
//                 </CommandLine>
//                 ))}
//                 <CommandLine>
//                     <LineSuffix><SuffixStart>dan@about-me:</SuffixStart><SuffixEnd>user$</SuffixEnd></LineSuffix>
//                     <EditableLine ref={textInput} contentEditable={true} onFocus={onFocus} onBlur={onBlur} onInput={ProcessInputInput} onKeyPress={ProcessKeyInput}></EditableLine>
//                 </CommandLine>
//             </Input>
//         </Scrollbars>
//     );
//   }

// export default ConsoleView;