import { GridLine, GridLineColumn1, GridLineColumn2, GridLineHeader } from '..';

const HeaderText: string = `This application was created as a technical exercise written in React.
The core idea was to create a unique way of navigating some ones Curriculum Vitae and experience information.

The application is split into contexts e.g. Technologies(tech) or Skills.
To get a list of a context use the 'list' command. To list all technologies you would enter 'list tech'.
To get a specific technology like '.Net Core' you would enter 'get tech .net core'
`

const CommandsText: string = `General Commands:
help
about
links

Context Commands:
list
get`

const CommandsDescriptions: string = `
Lists help information about using this console.
Gets information about Dan.
Lists links to Dans stuff.


Lists all the items within the given context.
Gets an individual item from a context.`

const ContextsText: string = `Contexts:
skills
tech
languages
interests`

const ContextsDescriptions: string = `
Key skills and their details.
Technologies that Dan has experience of and their details.
Scripting, programing and query languages.
Hobbies, interests and other stuff.`

function RenderHelp(){
    return(
        <GridLine>
            <GridLineHeader>
                {HeaderText}
            </GridLineHeader>
            <GridLineColumn1>
                {CommandsText}<br/><br/>
                {ContextsText}<br/>
            </GridLineColumn1>
            <GridLineColumn2>
                {CommandsDescriptions}<br/><br/>
                {ContextsDescriptions}<br/>
            </GridLineColumn2>
        </GridLine>
    )
}

export const HelpHandler = {
    process: function (context: string, commands: string[]) {
        var help = RenderHelp()
        return [
            {line: {content: help}, overwriteLast: false},
        ]
    }
}