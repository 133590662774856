import { CommandHandler } from '.';
import { GridLine, GridLineColumn1, Line } from '..';
import { CareerHighlight, CareerHighlights } from '../../../data/CareerHighlights';

const careerNames = CareerHighlights.map(career => (
    `${career.name}`
    )).join('\n')

function RenderCareersTable(){
    return(
        <GridLine>
            <GridLineColumn1>
                {careerNames}
            </GridLineColumn1>
        </GridLine>
    )
}

function RenderCareersDetail(career: CareerHighlight){
    var responseText = `Name: ${career?.name}
                Details: ${career?.details}`;

    return(
        <Line>
            {responseText} 
        </Line>
    )
}

function RenderError(){
    return(
        <Line>
            {`invalid command`}
        </Line>
    )
}
export const CareersHandler: CommandHandler = {
    process: function (command: string, args: string[]) {
        var error = RenderError();
        var response = {line: {content: error}, overwriteLast: false}

        if(command)
        {
            if(command == 'get')
            {
                var contextName = args.join(' ');
                var career = CareerHighlights.find(t=>  t.name.toLowerCase() == contextName);
                if(career)
                {
                    var responseDetail = RenderCareersDetail(career);
                    response = {line: {content: responseDetail}, overwriteLast: false}
                }                
            }
            else if(command == 'list'){
                var careerTable = RenderCareersTable();
                response = {line: {content: careerTable}, overwriteLast: false};
            }
        }
        return [
            response
        ]
    }
}