import React from 'react';

export type Interest= {
    name: string;
    details: string;
}

export const Interests: Interest[] = [
    {name: 'Woodworking', details: `Woodworking has been a failry recent interest for me. I find this relaxing and a break away from my other tech-based interests. So far I have only created some basic items or outdoor structures.`},
    {name: 'Arduino', details: `I have been playing around with Arduinos for years now and created many random projects using them. From a monitor backlight that matched the windows theme colour to replacing the internals of a remote-controlled car.`},
    {name: 'Raspberry Pi', details: `Raspberry Pi computers have been a great source of entertainment. Coupled with Arduinos I have used these to create smart device management and also created a Kubernetes cluster running k3s using several Pi computers.`},
    {name: 'Games', details: `As with many people I have been playing both computer and board games. These cover many genres from strategy to role playing or first person.`}
];
