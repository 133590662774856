import React from 'react';

export type Link= {
    name: string;
    uri: string;
}

export const Links: Link[] = [
    {name: 'GitHub', uri: 'https://github.com/danmiller515/'},
    {name: 'LinkedIn', uri: 'https://uk.linkedin.com/in/daniel-miller-67294462'},
];
