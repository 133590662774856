import styled from '@emotion/styled'
import { zoomIn } from 'react-animations';
import { keyframes } from '@emotion/react';

type WithChildren<T = {}> = 
T & { children?: React.ReactNode };
const bounceAnimation = keyframes`${zoomIn}`;

export function BlurredBox({ children}: WithChildren){

  const Container = styled.div`
    background-image: linear-gradient(to right, rgba(200,200,200,0.3), rgba(200,200,200,0));
    animation: 0.5s ${bounceAnimation};
    max-width: 1024px;
    border-radius: 4px;
    backdrop-filter: blur(20px);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 1rem;
    margin: 1rem;
    flex: 1
  `

    return (
          <Container>
            {children}
          </Container>
    );
}