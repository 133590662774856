import styled from '@emotion/styled';
import React from 'react';
import { Technologies } from '../../../data/Tech';
import Card from '../Card';

const Line = styled.div`
    margin-bottom: 0.2rem;
`

export const TechTable = ()=> {
    return(
        <div>
            {Technologies.map(technology => (
                <Line>
                    <Card key={technology.name} title={technology.name} rating={technology.rating} content={technology.details}  >
                    </Card>
                </Line>
            ))}
        </div>
    )
}