import styled from '@emotion/styled'
import { Interests } from '../../../data/Interests'

const Line = styled.div`
    margin-bottom: 1rem;
`
const Title = styled.div`

`
const Details = styled.div`

`
export const InterestsTable = ()=> {
    return(
        <div>
            {Interests.map(interest => (
                <Line>
                    <Title>{interest.name}</Title>
                    <Details>{interest.details}</Details>
                </Line>
            ))}
        </div>
    )
}